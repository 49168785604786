import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CatalogMetal from "../../components/CatalogMetal"

import { metalCars } from "../../components/CatalogMetal/data"

const CatalogMetalPage = props => {
  const { location } = props
  return (
    <Layout path={location.pathname}>
      {
        // eslint-disable-next-line
        <SEO
          title="Цены на металлические катализаторы"
          description="Каталог металлические катализаторов, которые мы выкупили у частных клиентов. Цены на них и данные по автомобилю, являющегося донором катализатора."
        />
      }
      <div className="overflow-hidden bg-gray-100 pt-20 lg:pt-16">
        <CatalogMetal data={metalCars} />
      </div>
    </Layout>
  )
}

export default CatalogMetalPage
